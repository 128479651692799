import type { Route } from '@/types/route'

export type FeatureFlag =
  | 'FEATURE_BOARDING_ROTA'
  | 'FEATURE_NEVERENDING_FEED'
  | 'FEATURE_BOARDING_COVERS'
  | 'FEATURE_BOARDING_COVERS_FOR_LEADER_MEETINGS'
  | 'FEATURE_T&E_ENROLMENTS_MANUAL_ARCHIVE'
  | 'IS_SUMMER_CAMPS_INSTANCE'

const availableFeature: string[] = import.meta.env.VITE_FEATURE_FLAGS
  ? import.meta.env.VITE_FEATURE_FLAGS.split(', ')
  : []

export function isFeatureEnabled(featureName: FeatureFlag) {
  return availableFeature.includes(featureName)
}

export function isSummerCampsInstance() {
  return isFeatureEnabled('IS_SUMMER_CAMPS_INSTANCE')
}

export function checkIsRouteWithFeatureEnabled(route: Route) {
  return route.meta?.featureFlag
    ? isFeatureEnabled(route.meta.featureFlag)
    : true
}

export function checkIsNotRouteWithFeatureDisabled(route: Route) {
  return route.meta?.disabledWithFeatureFlag
    ? !isFeatureEnabled(route.meta.disabledWithFeatureFlag)
    : true
}
