<template>
  <a-row type="flex" justify="space-between" class="header">
    <a-row type="flex" :gutter="gutter" style="flex: 1">
      <slot name="header" />
      <a-col
        v-for="(header, i) in headers"
        :key="header.key"
        class="sorter-wrapper"
        :style="colSpans[i] === undefined ? 'flex: 1' : ''"
        :span="colSpans[i]"
      >
        <div
          :class="{ sorter: header.sort }"
          role="button"
          data-cy="button"
          @click="handleClick(header)"
        >
          <slot
            v-if="header.customRender"
            :name="header.customRender"
            :header="header"
          />
          <div v-else style="display: flex">
            <h4 class="ant-list-item-meta-title" data-cy="item-title">
              {{ header.title }}
              <a-tooltip
                v-if="header.tooltipTitle"
                :title="header.tooltipTitle"
              >
                <a-icon :type="header.tooltipIcon" />
              </a-tooltip>
            </h4>
            <span v-if="header.sort" class="sorter-icon">
              <a-icon type="caret-up" :class="{ on: orderBy === header.key }" />
              <a-icon
                type="caret-down"
                :class="{ on: orderBy === `-${header.key}` }"
              />
            </span>
          </div>
        </div>
      </a-col>
    </a-row>
    <ul v-if="actionsCols > 0" class="ant-list-item-action" data-cy="list-item">
      <li v-for="index in actionsCols" :key="index">
        <span class="action-placeholder" />
      </li>
    </ul>
  </a-row>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useQueryModels } from '@/composables/useQueryModels'

type ListHeader = {
  title: string
  key: string
  customRender?: string
  sort?: boolean
  tooltipTitle?: string
  tooltipIcon?: string
}

withDefaults(
  defineProps<{
    headers: ListHeader[]
    actionsCols?: number
    colSpans?: (number | undefined)[]
    gutter?: number
  }>(),
  {
    actionsCols: 0,
    colSpans: () => [],
    gutter: 24
  }
)

const emit = defineEmits<{
  (name: 'sort', value: string): void
}>()

const { setQueryParam } = useQueryModels()

const orderBy = ref<string | null>(null)

const handleClick = ({ sort, key }: ListHeader) => {
  if (!sort) return

  const descending = `-${key}`

  orderBy.value =
    key === orderBy.value
      ? descending
      : orderBy.value === descending
        ? null
        : key

  setQueryParam('ordering', orderBy.value)

  if (typeof orderBy.value === 'string') {
    emit('sort', orderBy.value)
  }
}
</script>

<style lang="scss" scoped>
.action-placeholder {
  display: block;
  width: 40px;
  height: 0;
}

.sorter-wrapper {
  display: flex;
}

.sorter {
  display: flex;
  cursor: pointer;

  .ant-list-item-meta-title {
    color: var(--gray-color);
  }
}

.sorter-icon {
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  cursor: pointer;
  transform: scale(0.916667);

  .anticon {
    position: relative;
    color: #bfbfbf;
    font-size: 12px;
    transition: color 0.2s;

    &:first-child {
      top: 2px;
    }

    &:last-child {
      top: -2px;
    }

    &.on {
      color: #0b0204;
    }
  }
}
</style>
