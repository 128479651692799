import type { TAEDraftSectionsTaskStatusEnum } from '@/api/__generated-api'

type TAEDraftSectionsGenerationEventType =
  | 'tae_draft_sections_task_created'
  | 'tae_draft_sections_task_running'
  | 'tae_draft_sections_task_succeeded'
  | 'tae_draft_sections_task_cancelled'
  | 'tae_draft_sections_task_failed'

export type TAEDraftSectionsGenerationEventData = {
  event_type: TAEDraftSectionsGenerationEventType
  actor_full_name: string
  actor_id: string
  message: string
  tae_enrolment_id: number
  task_id: number
  status?: TAEDraftSectionsTaskStatusEnum
  started_at?: string
}

export const taeDraftSectionsGenerationNotifications: Record<
  TAEDraftSectionsTaskStatusEnum,
  string
> = {
  running: 'tae-draft-sections-generation-running',
  cancelled: 'tae-draft-sections-generation-cancelled',
  succeeded: 'tae-draft-sections-generation-succeeded',
  failed: 'tae-draft-sections-generation-failed',
  pending: 'tae-draft-sections-generation-pending'
} as const

export type TAEDraftSectionsGenerationWsEvent = {
  type: 'tae_enrolment_event'
  data: TAEDraftSectionsGenerationEventData
}
