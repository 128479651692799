<template>
  <a-layout-header class="header">
    <a-skeleton
      v-if="loading"
      class="skeleton"
      active
      :title="false"
      :paragraph="{ rows: 1 }"
    />
    <div v-else class="wrapper">
      <div class="title-wrapper">
        <slot name="title">
          <h1 data-cy="page-header-title">
            {{ title }}
            <sup v-if="superscriptText">({{ superscriptText }})</sup>

            <BaseInfoTooltip
              v-if="titleInfo"
              class="info-tooltip"
              :text="titleInfo"
              placement="bottom"
            />
          </h1>
        </slot>

        <p v-if="subtitle" class="t-label subtitle">
          {{ subtitle }}
        </p>
      </div>
      <BaseButton
        v-if="addButton"
        data-cy="add-item-button"
        icon="plus"
        @click="onButtonClick('addButton')"
      >
        {{ addButton.label }}
      </BaseButton>
      <BaseButton v-if="editButton" @click="onButtonClick('editButton')">
        {{ editButton.label }}
      </BaseButton>
      <slot />
    </div>
  </a-layout-header>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseInfoTooltip from '@/components/base/BaseInfoTooltip.vue'
import store from '@/store'

type Button = {
  action?: () => void
  label: string
  route?: { name: string }
}

const props = withDefaults(
  defineProps<{
    addButton?: Button
    editButton?: Button
    loading?: boolean
    subtitle?: string
    superscript?: string | number
    title?: string
    titleInfo?: string
  }>(),
  { subtitle: '', titleInfo: '' }
)

const router = useRouter()

const superscriptText = computed(() =>
  props.superscript !== undefined
    ? props.superscript
    : store.state.paginationTotal
)

const onButtonClick = (buttonType: 'addButton' | 'editButton') => {
  const buttonProps = props[buttonType]

  if (!buttonProps) return

  const { route, action } = buttonProps

  if (route) return router.push(route)
  if (action) return action()
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: unset;
  padding: 0;
  margin-bottom: 24px;

  h1 {
    line-height: 38px;
    margin: 0;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.subtitle {
  line-height: 1;
  margin-bottom: 0;
  position: absolute;
  bottom: 36px;
  font-weight: 600;
}

:deep(.info-icon) {
  font-size: 21px;
  vertical-align: baseline;
}

:deep(sup) {
  font-size: 66%;
}

:deep(.skeleton) {
  ul {
    margin: 0;

    li {
      height: 38px;
    }
  }
}
</style>
