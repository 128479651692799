import StudentIcon from '@/components/icons/GraduateIcon.vue'
import { type Route } from '@/types/route'

export const teEnrolmentNotTabRoutes = [
  {
    path: 'talent-and-enrichment/add',
    name: 'tae-enrolment-add' as const,
    component: () => import('@/views/Planner/TalentEnrichment/add.vue'),
    meta: {
      title: 'Plan Talent & Enrichment',
      permissions: ['tae_enrolment.add_taeenrolment']
    }
  },

  /**
   ** FORM TAB
   */
  {
    path: 'talent-and-enrichment/:enrolmentId/form',
    name: 'tae-enrolment-form' as const,
    redirect: { name: 'tae-enrolment-form-courses' },
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        path: 'edit',
        name: 'tae-enrolment-form-edit' as const,
        component: () =>
          import('@/views/Planner/TalentEnrichment/form/edit.vue'),
        meta: {
          title: 'Plan Talent & Enrichment',
          permissions: ['tae_enrolment.change_taeenrolment']
        }
      },
      {
        path: 'courses',
        name: 'tae-enrolment-form-courses' as const,
        component: () =>
          import('@/views/Planner/TalentEnrichment/form/courses.vue'),
        meta: {
          title: 'Plan Talent & Enrichment',
          permissions: ['tae_enrolment.view_taeenrolment']
        }
      }
    ]
  },

  /**
   ** ENROLMENT TAB
   */
  {
    path: 'talent-and-enrichment/:enrolmentId/enrolment',
    name: 'tae-enrolment-enrolment' as const,
    redirect: { name: 'tae-enrolment-enrolment-courses' },
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        path: 'courses',
        name: 'tae-enrolment-enrolment-courses' as const,
        component: () =>
          import('@/views/Planner/TalentEnrichment/enrolment/courses.vue'),
        meta: {
          title: 'Plan Talent & Enrichment',
          permissions: ['tae_enrolment.view_taecourseenrollment']
        }
      },
      {
        path: 'students',
        name: 'tae-enrolment-enrolment-students' as const,
        component: () =>
          import('@/views/Planner/TalentEnrichment/enrolment/students.vue'),
        meta: {
          title: 'Plan Talent & Enrichment',
          permissions: ['tae_enrolment.view_taestudentenrollment']
        }
      }
    ]
  },

  /**
   ** SECTIONS TAB
   */
  {
    path: 'talent-and-enrichment/:enrolmentId/sections',
    name: 'tae-enrolment-sections' as const,
    redirect: { name: 'tae-enrolment-sections-first-quarter' },
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        path: 'first-quarter',
        name: 'tae-enrolment-sections-first-quarter' as const,
        component: () =>
          import('@/views/Planner/TalentEnrichment/sections/index.vue'),
        meta: {
          title: 'Plan Talent & Enrichment',
          permissions: ['tae_draft_sections.view_taedraftsection']
        }
      },
      {
        path: 'second-quarter',
        name: 'tae-enrolment-sections-second-quarter' as const,
        component: () =>
          import('@/views/Planner/TalentEnrichment/sections/index.vue'),
        meta: {
          title: 'Plan Talent & Enrichment',
          permissions: ['tae_draft_sections.view_taedraftsection']
        }
      }
    ]
  }
] satisfies Route[]

export const teEnrolmentTabRoute = {
  path: 'talent-and-enrichment',
  name: 'tae-enrolment' as const,
  component: () => import('@/views/Planner/TalentEnrichment/index.vue'),
  meta: {
    title: 'Talent & Enrichment',
    label: 'Talent & Enrichment',
    iconComponent: StudentIcon,
    permissions: ['tae_enrolment.view_taeenrolment']
  }
} satisfies Route
